/* --------------------------------------------------
  Utils
-------------------------------------------------- */
.c-blue {
  color: var(--c-blue);
}

.c-red {
  color: var(--c-red);
}

.mr-2sp {
  margin-right: 2rem;
}

.mr-3sp {
  margin-right: 3rem;
}

.mb-3sp {
  margin-bottom: 3rem;
}

.mt-7sp {
  margin-top: 7rem;
}

.pt-8sp {
  padding-top: 8rem;
}

.pt-10sp {
  padding-top: 10rem;
}

.pb-8sp {
  padding-bottom: 8rem;
}

.pb-10sp {
  padding-bottom: 10rem;
}

.py-8sp {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.text-right {
  text-align: right;
}

.font-size-1 {
  font-size: var(--font-size-1);
}
.font-size-2 {
  font-size: var(--font-size-2);
}
.font-size-3 {
  font-size: var(--font-size-3);
}
.font-size-4 {
  font-size: var(--font-size-4);
}
.font-size-5 {
  font-size: var(--font-size-5);
}
.font-size-6 {
  font-size: var(--font-size-6);
}
.font-size-7 {
  font-size: var(--font-size-7);
}
.font-size-8 {
  font-size: var(--font-size-8);
}

.thick-border-left {
  border-left: 0.4rem solid var(--c-blue);
}
