@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap');
@import url('_design-tokens.css');
@import url('_utils.css');

* {
  font-family: var(--font-sans);
}

html {
  font-size: 62.5%;
}

body {
  font-size: var(--font-size-5);
  margin: 0;
  color: var(--c-text-primary);
}

h1 {
  font-size: var(--font-size-8);
}

a {
  color: var(--link-color);
  font-weight: bold;
}

a:hover {
  color: var(--link-color-hover);
}

.primary {
  color: var(--c-blue);
}


.text-link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
}

/* --------------------------------------------------
  Buttons
-------------------------------------------------- */
.btn {
  border-radius: 0;
  font-weight: bold;
  font-size: var(--btn-font-size);
  text-transform: uppercase;
}

.btn-primary {
  background-color: var(--c-blue);
  border-color: var(--c-blue);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--btn-primary-bg);
  border: var(--btn-primary-border);
  opacity: var(--btn-disabled-opacity);
}

.btn-primary:hover {
  background-color: var(--link-color-hover);
  border-color: var(--link-color-hover);
}

.btn-danger {
  background-color: var(--c-red);
  border-color: var(--c-red);
}

.btn-danger.disabled,
.btn-danger:disabled {
  /* background-color: var(--btn-primary-bg);
  border: var(--btn-primary-border); */
  opacity: var(--btn-disabled-opacity);
}

.btn-danger:hover {
  background-color: var(--c-red-90);
  border-color: var(--c-red-90);
}

.btn-outline-primary {
  color: var(--c-blue);
  border-color: var(--c-blue);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--c-blue);
  border-color: var(--c-blue);
}

.btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4rem;
  text-decoration: none;
  text-transform: none;
  color: var(--link-color);
}

.btn-icon i,
.btn-link i {
  font-size: var(--btn-icon-font-size);
  height: var(--btn-icon-font-size);
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 1rem 1.5rem;
}

.info-header {
  display: flex;
  align-items: center;
}

.info-header-col {
  padding: 0.5rem 1rem;
  font-weight: bolder;
}

.info-content {
  padding: 0.5rem 1rem;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/* --------------------------------------------------
  Form
-------------------------------------------------- */
.form-control,
.form-select {
  font-size: var(--input-font-size);
}

.form-control {
  border-radius: 0;
}

.form-label {
  margin-bottom: 0;
}

/* --------------------------------------------------
  Tabs
-------------------------------------------------- */
.nav-tabs {
  border-bottom-color: var(--line-separator-bg);
}

.nav-tabs .nav-item {
  margin-right: var(--tab-space-right);
}

.nav-tabs .nav-link {
  border: 0;
  font-size: var(--tab-font-size);
  font-weight: bold;
  text-transform: var(--tab-text-transform);
  text-decoration: none;
  position: relative;
  display: inline-block;
  color: var(--tab-color);
  padding-left: 0;
  padding-right: 0;
}

.nav-tabs .nav-link:after {
  content: '';
  position: absolute;
  height: var(--tab-active-border-height);
  width: 100%;
  background-color: var(--tab-active-border-color);
  bottom: 0;
  right: 0;
  transform: scaleX(0);
  transform-origin: left bottom;
  transition: transform 0.2s;
}

.nav-tabs .nav-link:hover {
  color: var(--tab-active-color);
}

.nav-tabs .nav-link:hover:after {
  transform: scaleX(1);
}

.nav-tabs .nav-link.active {
  color: var(--tab-active-color);
}

.nav-tabs .nav-link.active:after {
  transform: scaleX(1);
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1208px;
  }
}

.global-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
}

.text-red {
  color: var(--c-red);
}

/* --------------------------------------------------
  Modal
-------------------------------------------------- */
.modal {
  top: 8rem;
}

/* --------------------------------------------------
  Sections
-------------------------------------------------- */
.logo-redattore {
  width: 38rem;
}

.landing-ul {
  list-style: none;
  margin-left: 0.8rem;
  padding: 0;
}

.landing-ul li {
  margin-bottom: 0.8rem;
  position: relative;
  margin-left: 1.6rem;
}

.landing-ul li::before {
  content: '';
  width: 0.6rem;
  height: 1.2rem;
  background: var(--c-red);
  display: inline-block;
  position: absolute;
  top: 0.5rem;
  left: -2.4rem;
}

/* --------------------------------------------------
  Modal help
-------------------------------------------------- */
.info-header .gfl-icon {
  font-size: 4rem;
}
