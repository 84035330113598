:root {
  /*  Cube Theme - Ver. 3.2.2 */
  /*   */
  /*  Project Repository */
  /*  http://gitlab.sviluppomc.giuffre.it/alessandro/cube-theme/-/tree/3.2.2 */
  /*  */
  /*  *** DO NOT EDIT THIS FILE *** */
  /*  If needed, override these variables from another file  */

  /*  -------------------------------------------------- */
  /*  Palette */
  /*  -------------------------------------------------- */
  --c-grey-10: hsl(215deg, 25%, 98%);
  --c-grey-20: hsl(215deg, 24%, 96%);
  --c-grey-30: hsl(215deg, 24%, 94%);
  --c-grey-40: hsl(215deg, 23%, 92%);
  --c-grey-50: hsl(216deg, 23%, 90%);
  --c-grey-60: hsl(216deg, 21%, 80%);
  --c-grey-70: hsl(217deg, 19%, 70%);
  --c-grey-80: hsl(217deg, 17%, 60%);
  --c-grey-90: hsl(218deg, 12%, 36%);
  --c-grey-100: hsl(221deg, 10%, 16%);

  --c-blue-10: hsl(215deg, 78%, 98%);
  --c-blue-20: hsl(215deg, 78%, 96%);
  --c-blue-30: hsl(215deg, 78%, 94%);
  --c-blue-40: hsl(216deg, 77%, 92%);
  --c-blue-50: hsl(216deg, 77%, 90%);
  --c-blue-60: hsl(216deg, 76%, 80%);
  --c-blue-70: hsl(218deg, 74%, 60%);
  --c-blue-80: hsl(219deg, 72%, 40%);
  --c-blue-90: hsl(220deg, 71%, 26%);
  --c-blue-100: hsl(221deg, 70%, 14%);

  --c-red-10: hsl(0deg, 51%, 98%);
  --c-red-20: hsl(0deg, 51%, 96%);
  --c-red-30: hsl(0deg, 51%, 94%);
  --c-red-40: hsl(0deg, 51%, 92%);
  --c-red-50: hsl(0deg, 51%, 90%);
  --c-red-60: hsl(0deg, 51%, 80%);
  --c-red-70: hsl(0deg, 51%, 50%);
  --c-red-80: hsl(0deg, 51%, 40%);
  --c-red-90: hsl(0deg, 51%, 30%);
  --c-red-100: hsl(0deg, 51%, 10%);

  --c-blue: #133371;
  --c-cyan: hsl(196deg, 100%, 43%);
  --c-red: #a53535;
  --c-white: #fff;

  /*  Status colors */
  --c-neutral: var(--c-grey-80);

  --c-success-light: hsl(135deg, 40%, 98%);
  --c-success: hsl(144deg, 100%, 29%);
  --c-success-dark: hsl(144deg, 100%, 13%);

  --c-warning-light: hsl(40deg, 82%, 98%);
  --c-warning: hsl(47deg, 81%, 52%);
  --c-warning-dark: hsl(47deg, 80%, 16%);

  --c-danger-light: hsl(0deg, 60%, 98%);
  --c-danger: hsl(0deg, 81%, 61%);
  --c-danger-dark: hsl(0deg, 90%, 30%);

  --c-info-light: hsl(205deg, 60%, 96%);
  --c-info: var(--c-cyan);
  --c-info-dark: hsl(201deg, 61%, 19%);

  --c-active-element: var(--c-red);

  /*  Other */
  --c-polisweb-light: #ecf3ec;
  --c-polisweb: #157113;

  /*  -------------------------------------------------- */
  /*  Typography */
  /*  -------------------------------------------------- */
  --font-sans: 'Quattrocento Sans', sans-serif;
  --font-titles: 'Aeroportal', 'Quattrocento Sans', sans-serif;
  --font-code: 'Fira Code', Courier, monospace;

  --font-size-1: 1rem;
  --font-size-2: 1.1rem;
  --font-size-3: 1.2rem;
  --font-size-4: 1.4rem;
  --font-size-5: 1.6rem;
  --font-size-6: 2rem;
  --font-size-7: 2.4rem;
  --font-size-8: 3.2rem;

  /*  -------------------------------------------------- */
  /*  Text colors */
  /*  -------------------------------------------------- */
  --c-text-primary: var(--c-grey-100);
  --c-text-secondary: var(--c-grey-90);
  --c-text-tertiary: var(--c-grey-80);
  --c-text-muted: var(--c-text-secondary);
  --c-text-disabled: var(--c-text-tertiary);

  /*  -------------------------------------------------- */
  /*  Transitions */
  /*  -------------------------------------------------- */
  --transition-duration-1: 0.2s;

  /*  -------------------------------------------------- */
  /*  Shadows */
  /*  -------------------------------------------------- */
  --shadow-1: 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  --shadow-2: 0 0.2rem 0.1rem rgba(0, 0, 0, 0.1);

  /*  -------------------------------------------------- */
  /*  Links */
  /*  -------------------------------------------------- */
  --link-color: var(--c-blue-90);
  --link-color-hover: var(--c-blue-80);
  --link-color-active: var(--c-blue-70);
  --link-ghost-hover-bg: var(--c-blue-20);
  --link-focus-outline: none;
  --link-focus-shadow-color: var(--c-blue-60);
  --link-focus-shadow: 0 0 0 0.3rem var(--link-focus-shadow-color);
  --link-focus-transition-duration: var(--transition-duration-1);

  /*  -------------------------------------------------- */
  /*  Spacing */
  /*  -------------------------------------------------- */
  --space-unit: 0.8rem;

  /*  -------------------------------------------------- */
  /*  Border */
  /*  -------------------------------------------------- */
  --border-radius: 0;
  --border-radius-rounded: 0.4rem;
  --border-color: var(--c-grey-70);

  /*  -------------------------------------------------- */
  /*  Inputs */
  /*  -------------------------------------------------- */
  --input-border-radius: var(--border-radius);
  --input-height: 3.2rem;
  --input-border-color: var(--border-color);
  --input-border: 1px solid var(--input-border-color);
  --input-font-family: var(--font-sans);
  --input-font-size: var(--font-size-4);
  --input-padding-horizontal: var(--space-unit);
  --input-readonly-bg: var(--c-grey-20);
  --input-readonly-color: var(--c-text-tertiary);
  --input-focus-outline: none;
  --input-focus-border-color: var(--link-color-active);
  --input-focus-outline-color: var(--link-focus-shadow-color);
  --input-focus-outline: 0.2rem solid var(--input-focus-outline-color);

  /*  -------------------------------------------------- */
  /*  Form */
  /*  -------------------------------------------------- */
  --form-font-size: var(--input-font-size);
  --form-group-spacing-bottom: 1.6rem;
  --form-input-min-height: var(--input-height);
  --form-input-margin-bottom: 0.8rem;
  --form-input-readonly-bg: var(--c-grey-30);
  --form-input-readonly-color: var(--c-text-secondary);
  --form-input-border-color: var(--c-grey-60);
  --form-input-placeholder-color: var(--c-grey-70);
  --form-input-tags-padding-horizontal: 0.2rem;
  --form-widget-v-space: 0.8rem;
  --form-label-width: 17rem;
  --form-label-margin-bottom: 0.8rem;
  --form-inner-group-padding-horizonal: 1.6rem;

  /*  Checkbox */
  --checkbox-border-radius: var(--input-border-radius);
  --checkbox-disabled-bg: var(--c-grey-30);
  --checkbox-disabled-color: var(--c-grey-80);
  --checkbox-size-md: 2.4rem;
  --checkbox-size-sm: 1.6rem;

  /*  -------------------------------------------------- */
  /*  Buttons */
  /*  -------------------------------------------------- */
  --btn-font-size: var(--input-font-size);
  --btn-font-weight: bold;
  --btn-text-transform: uppercase;
  --btn-disabled-opacity: 0.3;
  --btn-ghost-hilight-bg: var(--c-blue-20);
  --btn-border-radius: var(--border-radius);
  --btn-icon-font-size: 2rem;
  --btn-shadow: var(--shadow-1);
  --btn-focus-outline: var(--input-focus-outline);
  --btn-focus-shadow: var(--input-focus-outline);
  --btn-transition-duration: var(--transition-duration-1);

  --btn-primary-color: var(--c-white);
  --btn-primary-bg: var(--link-color);
  --btn-primary-border: 1px solid var(--btn-primary-bg);
  --btn-primary-hover-color: var(--btn-primary-color);
  --btn-primary-hover-bg: var(--link-color-hover);
  --btn-primary-hover-border: 1px solid var(--btn-primary-hover-bg);
  --btn-primary-active-color: var(--c-white);
  --btn-primary-active-bg: var(--link-color-active);
  --btn-primary-active-border: 1px solid var(--btn-primary-active-bg);

  --btn-secondary-color: var(--link-color);
  --btn-secondary-bg: var(--c-white);
  --btn-secondary-border: 1px solid var(--btn-secondary-color);
  --btn-secondary-hover-color: var(--link-color);
  --btn-secondary-hover-bg: var(--btn-ghost-hilight-bg);
  --btn-secondary-hover-border: 1px solid var(--btn-secondary-hover-color);
  --btn-secondary-active-color: var(--link-color-active);
  --btn-secondary-active-bg: var(--btn-ghost-hilight-bg);
  --btn-secondary-hover-border: 1px solid var(--btn-secondary-active-color);

  --btn-tertiary-color: var(--link-color);
  --btn-tertiary-bg: var(--c-white);
  --btn-tertiary-border: 1px solid var(--btn-tertiary-bg);
  --btn-tertiary-hover-color: var(--link-color);
  --btn-tertiary-hover-bg: var(--btn-ghost-hilight-bg);
  --btn-tertiary-hover-border: 1px solid var(--btn-tertiary-hover-bg);
  --btn-tertiary-active-color: var(--link-color-active);
  --btn-tertiary-active-bg: var(--btn-ghost-hilight-bg);
  --btn-tertiary-active-border: 1px solid var(--btn-tertiary-active-bg);

  /*  -------------------------------------------------- */
  /*  Cards */
  /*  -------------------------------------------------- */
  --card-border-radius: 4px;
  --card-box-shadow: var(--shadow-1);

  /*  -------------------------------------------------- */
  /*  Transitions */
  /*  -------------------------------------------------- */
  --timing-default: 0.3s;
  --timing-buttons: 0.2s;

  /*  -------------------------------------------------- */
  /*  Misc */
  /*  -------------------------------------------------- */
  --line-separator-bg: var(--border-color);

  /*  -------------------------------------------------- */
  /*  Page */
  /*  -------------------------------------------------- */
  --page-bg-color: var(--c-grey-40);

  /*  -------------------------------------------------- */
  /*  Table */
  /*  -------------------------------------------------- */
  --table-line-separator-color: var(--c-grey-50);
  --table-thead-bg: var(--c-grey-20);
  --table-font-size: var(--font-size-4);
  --table-th-color: var(--c-text-primary);
  --table-th-font-size: var(--font-size-3);
  --table-th-font-weight: bold;
  --table-th-text-transform: uppercase;
  --table-th-sorted-color: var(--c-text-primary);
  --table-th-sorted-arrow-color: var(--c-active-element);
  --table-th-resize-bg: var(--c-grey-50);
  --table-th-resize-hover-bg: var(--c-grey-60);
  --table-th-border-radius: 0;
  --table-group-bg: var(--c-grey-30);
  --table-row-selected-bg: var(--c-blue-20);

  /*  -------------------------------------------------- */
  /*  Tab */
  /*  -------------------------------------------------- */
  --tab-color: var(--c-text-tertiary);
  --tab-font-size: var(--font-size-4);
  --tab-font-weight: bold;
  --tab-text-transform: uppercase;
  --tab-height: calc(var(--space-unit) * 4);
  --tab-focus-background: var(--c-red-20);
  --tab-active-color: var(--c-active-element);
  --tab-active-border-color: var(--c-active-element);
  --tab-active-border-height: 0.3rem;
  --tab-space-right: calc(var(--space-unit) * 3);

  /*  -------------------------------------------------- */
  /*  Scrollbar */
  /*  -------------------------------------------------- */
  --scrollbar-foreground: var(--c-grey-70);

  /*  -------------------------------------------------- */
  /*  Toaster */
  /*  -------------------------------------------------- */
  --toaster-border: 0;
  --toaster-border-left-width: 0.4rem;
  --toaster-padding: calc(var(--space-unit) * 2);
}
